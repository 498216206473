// router.js
import Vue from "vue";
import VueRouter from "vue-router";
// 越南namvaytien
// import MyFirst from './namvaytien/MyFirst.vue'
// import MyAbout from './namvaytien/MyAbout.vue'
// import MyProtocol from './namvaytien/MyProtocol.vue'
// import MyQuestion from './namvaytien/MyQuestions.vue'

//泰国chatchaimobile
// import MyFirst from './chatchaimobile/MyFirst.vue'
// import MyProtocol from './chatchaimobile/MyProtocol.vue'

//泰国jaidee
// import MyFirst from './jaidee/MyFirst.vue'
// import MyProtocol from './jaidee/MyProtocol.vue'
// import MyService from './jaidee/MyService.vue'
// import MyQuestion from './jaidee/MyQuestions.vue'
//泰国第二个jaidee
// import MyFirst from './weimingm/MyFirst.vue'
// import MyProtocol from './weimingm/MyProtocol.vue'
// import MyAbout from './weimingm/MyAbout.vue'
// import MyClause from './weimingm/MyClause.vue'

//越南kasikvay
// import MyFirst from './kasikvay/MyFirst.vue'

// import MyAbout from './kasikvay/MyAbout.vue'
// import MyClause from './kasikvay/MyClause.vue'
// import MyQuestions from './kasikvay/MyQuestions.vue'
// import MyProtocol from './kasikvay/MyProtocol.vue'

//墨西哥tamogiefectivo
// import MyFirst from './tamogiefectivo/MyFirst.vue'
// import MyProtocol from './tamogiefectivo/MyProtocol.vue'
// import MyAbout from './tamogiefectivo/MyAbout.vue'
// import MyQuestion from './tamogiefectivo/MyQuestions.vue'
// import MyCenter from './tamogiefectivo/MyCenter.vue'

//墨西哥consedeefectivo
// import MyFirst from './consedeefectivo/MyFirst.vue'
// import MyAbout from './consedeefectivo/MyAbout.vue'
// import MyService from './consedeefectivo/MyService.vue'
// import MyProtocol from './consedeefectivo/MyProtocol.vue'

//墨西哥betacreditdinero
// import MyFirst from './betacreditdinero/MyFirst.vue'
// import MyAbout from './betacreditdinero/MyAbout.vue'
// import MyContact from './betacreditdinero/MyContact.vue'
// import MyProtocol from './betacreditdinero/MyProtocol.vue'

//墨西哥cherrypre
// import MyFirst from './cherreypréstamos/MyFirst.vue'
// import MyAbout from './cherreypréstamos/MyAbout.vue'
// import MyService from './cherreypréstamos/MyService.vue'
// import MyQuestions from './cherreypréstamos/MyQuestions.vue'
// import MyProtocol from './cherreypréstamos/MyProtocol.vue'

//越南nathcopay
// import MyFirst from './nathcopay/MyFirst.vue'
// import MyQuestions from './nathcopay/MyQuestions.vue'
// import MyProtocol from './nathcopay/MyProtocol.vue'

//越南mrVaymobile
// import MyFirst from './mrVaymobile/MyFirst.vue'
// import MyProtocol from './mrVaymobile/MyProtocol.vue'
// import MyAbout from './mrVaymobile/MyAbout.vue'
// import MyContact from './mrVaymobile/MyContact.vue'

//越南hcthlogplus
// import MyFirst from './hcthlogplus/MyFirst.vue'
// import MyProtocol from './hcthlogplus/MyProtocol.vue'
// import MyAbout from './hcthlogplus/MyAbout.vue'
// import MyProcess from './hcthlogplus/MyProcess.vue'

//越南hipotecariabilletera
// import MyFirst from './hipotecariabilletera/MyFirst.vue'
// import MyProtocol from './hipotecariabilletera/MyProtocol.vue'
// import MyPaso from './hipotecariabilletera/MyPaso.vue'
// import MyProcess from './hipotecariabilletera/MyQuestions.vue'
// import MyContact from './hipotecariabilletera/MyContact.vue'
// import MyContrato from './hipotecariabillsubetera/MyContrato.vue'

// 越南sunmobile
// import MyFirst from './sunmobile/MyFirst.vue'
// import MyProtocol from './sunmobile/MyProtocol.vue'
// import MyPaso from './sunmobile/MyPaso.vue'
// import MyQuestions from './sunmobile/MyQuestions.vue'
// import MyContact from './sunmobile/MyContact.vue'

// 越南cathaydonge
// import MyFirst from './cathaydong/MyFirst.vue'
// import MyProtocol from './cathaydong/MyProtocol.vue'
// import MyQuestions from './cathaydong/MyQuestions.vue'
// import MyService from './cathaydong/MyService.vue'

// 越南newvay
// import MyFirst from './newvay/MyFirst.vue'
// import MyProtocol from './newvay/MyProtocol.vue'
// import MyQuestions from './newvay/MyQuestions.vue'
// import MyAbout from './newvay/MyAbout.vue'
// import MyService from './newvay/MyService.vue'

//墨西哥eldefiardinero
// import MyFirst from './eldefiardinero/MyFirst.vue'
// import MyProtocol from './eldefiardinero/MyProtocol.vue'
// import MyQuestions from './eldefiardinero/MyQuestions.vue'
// import MyAbout from './eldefiardinero/MyAbout.vue'
// import MyPaso from './eldefiardinero/MyNews.vue'

//墨西哥thanhchungplus
// import MyFirst from './thanhchungplus/MyFirst.vue'
// import MyProtocol from './thanhchungplus/MyProtocol.vue'
// import MyQuestions from './thanhchungplus/MyQuestions.vue'
// import MyAbout from './thanhchungplus/MyAbout.vue'
// import MyPaso from './thanhchungplus/MyNews.vue'

//墨西哥dealcorpefectivo
// import MyFirst from './dealcorpefectivo/MyFirst.vue'
// import MyProtocol from './dealcorpefectivo/MyProtocol.vue'
// import MyService from './dealcorpefectivo/MyService.vue'
// import MyContact from './dealcorpefectivo/MyContact.vue'

//墨西哥kampalacash
// import MyFirst from './kampalacash/MyFirst.vue'
// import MyProtocol from './kampalacash/MyProtocol.vue'
// import MyService from './kampalacash/MyService.vue'
// import MyAbout from './kampalacash/MyAbout.vue'

// 泰国pheera
// import MyFirst from './pheera/MyFirst.vue'
// import MyProtocol from './pheera/MyProtocol.vue'
// import MyPaso from './pheera/MyService.vue'
// import MyQuestions from './pheera/MyQuestions.vue'
// import MyContact from './pheera/MyContact.vue'
// import MyAbout from './pheera/MyAbout.vue'

// 越南piwebplus
// import MyFirst from './piwebplus/MyFirst.vue'
// import MyProtocol from './piwebplus/MyProtocol.vue'
// import MyPaso from './piwebplus/MyService.vue'
// import MyQuestions from './piwebplus/MyQuestions.vue'
// import MyContact from './piwebplus/MyAbout.vue'

// 越南piwebplus2
// import MyFirst from './piwebplus2/MyFirst.vue'
// import MyProtocol from './piwebplus2/MyProtocol.vue'
// import MyPaso from './piwebplus2/MyService.vue'
// import MyQuestions from './piwebplus2/MyQuestions.vue'
// import MyContact from './piwebplus2/MyAbout.vue'

// 墨西哥directodoefectivo
// import MyFirst from './directodoefectivo/MyFirst.vue'
// import MyProtocol from './directodoefectivo/MyProtocol.vue'
// import MyPaso from './directodoefectivo/MyService.vue'
// import MyQuestions from './directodoefectivo/MyQuestions.vue'
// import MyContact from './directodoefectivo/MyAbout.vue'

// 泰国Jaideeonline
// import MyFirst from './Jaideeonline/MyFirst.vue'
// import MyProtocol from './Jaideeonline/MyProtocol.vue'
// import MyPaso from './Jaideeonline/MyService.vue'
// import MyQuestions from './Jaideeonline/MyQuestions.vue'
// import MyContact from './Jaideeonline/MyAbout.vue'

// 墨西哥braskemefectivo
// import MyFirst from './braskemefectivo/MyFirst.vue'
// import MyProtocol from './braskemefectivo/MyProtocol.vue'
// import MyPaso from './braskemefectivo/MyService.vue'
// import MyQuestions from './braskemefectivo/MyQuestions.vue'
// import MyContact from './braskemefectivo/MyAbout.vue'

// 墨西哥dealcorpefectivo2
// import MyFirst from './dealcorpefectivo2/MyFirst.vue'
// import MyProtocol from './dealcorpefectivo2/MyProtocol.vue'
// import MyPaso from './dealcorpefectivo2/MyService.vue'
// import MyQuestions from './dealcorpefectivo2/MyQuestions.vue'
// import MyContact from './dealcorpefectivo2/MyAbout.vue'

// 墨西哥ceibamóvil
// import MyFirst from './ceibamóvil/MyFirst.vue'
// import MyProtocol from './ceibamóvil/MyProtocol.vue'
// import MyPaso from './ceibamóvil/MyService.vue'
// import MyQuestions from './ceibamóvil/MyQuestions.vue'
// import MyContact from './ceibamóvil/MyAbout.vue'
// import MyCommunity from './ceibamóvil/MyCommunity.vue'

// 墨西哥estimulocrédito
// import MyFirst from './estimulocrédito/MyFirst.vue'
// import MyProtocol from './estimulocrédito/MyProtocol.vue'
// import MyPaso from './estimulocrédito/MyService.vue'
// import MyQuestions from './estimulocrédito/MyQuestions.vue'
// import MyContact from './estimulocrédito/MyAbout.vue'
// import MyHelp from './estimulocrédito/MyHelp.vue'
// import MyProcess from './estimulocrédito/MyProcess.vue'
// import MyCondition from './estimulocrédito/MyCondition.vue'

// 墨西哥cincodospréstamos
// import MyFirst from './cincodospréstamos/MyFirst.vue'
// import MyProtocol from './cincodospréstamos/MyProtocol.vue'
// import MyPaso from './cincodospréstamos/MyService.vue'
// import MyQuestions from './cincodospréstamos/MyQuestions.vue'
// import MyContact from './cincodospréstamos/MyAbout.vue'
// import MyCommunity from './cincodospréstamos/MyCommunity.vue'
// import MyTips from './cincodospréstamos/MyTips.vue'

// 墨西哥concreecefectivo
// import MyFirst from './concreecefectivo/MyFirst.vue'
// import MyProtocol from './concreecefectivo/MyProtocol.vue'
// import MyPaso from './concreecefectivo/MyService.vue'
// import MyQuestions from './concreecefectivo/MyQuestions.vue'
// import MyContact from './concreecefectivo/MyAbout.vue'
// import MyCommunity from './concreecefectivo/MyCommunity.vue'

// // 墨西哥daraptipago和wiicredi
// import MyFirst from './daraptipago/MyFirst.vue'
// import MyProtocol from './daraptipago/MyProtocol.vue'
// import MyPaso from './daraptipago/MyService.vue'
// import MyQuestions from './daraptipago/MyQuestions.vue'
// import MyContact from './daraptipago/MyAbout.vue'

// 泰国microfinmobile
// import MyFirst from './microfinmobile/MyFirst.vue'
// import MyProtocol from './microfinmobile/MyProtocol.vue'
// import MyPaso from './microfinmobile/MyService.vue'
// import MyQuestions from './microfinmobile/MyQuestions.vue'
// import MyContact from './microfinmobile/MyAbout.vue'
// import MyCommunity from './microfinmobile/MyCommunity.vue'

//墨西哥ammóvil
// import MyFirst from './ammóvil/MyFirst.vue'
// import MyProtocol from './ammóvil/MyProtocol.vue'
// import MyPaso from './ammóvil/MyService.vue'
// import MyQuestions from './ammóvil/MyQuestions.vue'
// import MyContact from './ammóvil/MyAbout.vue'

// 墨西哥ngernsaen
// import MyFirst from './ngernsaen/MyFirst.vue'
// import MyProtocol from './ngernsaen/MyProtocol.vue'
// import MyPaso from './ngernsaen/MyService.vue'
// import MyQuestions from './ngernsaen/MyQuestions.vue'
// import MyContact from './ngernsaen/MyAbout.vue'

// 泰国domcap
// import MyFirst from './domcap/MyFirst.vue'
// import MyProtocol from './domcap/MyProtocol.vue'
// import MyPaso from './domcap/MyService.vue'
// import MyQuestions from './domcap/MyQuestions.vue'
// import MyContact from './domcap/MyAbout.vue'

//墨西哥cherreypréstamos2
// import MyFirst from './cherreypréstamos2/MyFirst.vue'
// import MyAbout from './cherreypréstamos2/MyAbout.vue'
// import MyService from './cherreypréstamos2/MyService.vue'
// import MyQuestions from './cherreypréstamos2/MyQuestions.vue'
// import MyProtocol from './cherreypréstamos2/MyProtocol.vue'
// import MyHelp from './cherreypréstamos2/MyHelp.vue'
// import MyProtocol2 from './cherreypréstamos2/MyProtocol2.vue'

//墨西哥facicasapresta
// import MyFirst from './facicasapresta/MyFirst.vue'
// import MyProtocol from './facicasapresta/MyProtocol.vue'
// import MyService from './facicasapresta/MyService.vue'
// import MyQuestions from './facicasapresta/MyQuestions.vue'
// import MyAbout from './facicasapresta/MyAbout.vue'

// 墨西哥eliteloan
// import MyFirst from './eliteloan/MyFirst.vue'
// import MyProtocol from './eliteloan/MyProtocol.vue'
// import MyPaso from './eliteloan/MyService.vue'
// import MyQuestions from './eliteloan/MyQuestions.vue'
// import MyContact from './eliteloan/MyAbout.vue'

//越南drkinhtiềnbạc
// import MyFirst from './drkinhtiềnbạc/MyFirst.vue'
// import MyProtocol from './drkinhtiềnbạc/MyProtocol.vue'
// import MyPaso from './drkinhtiềnbạc/MyService.vue'
// import MyTechno from './drkinhtiềnbạc/MyTechno.vue'
// import MyContact from './drkinhtiềnbạc/MyAbout.vue'

//越南vajefinance
// import MyFirst from './vajefinance/MyFirst.vue'
// import MyProtocol from './vajefinance/MyProtocol.vue'
// import MyPaso from './vajefinance/MyService.vue'
// import MyTechno from './vajefinance/MyTechno.vue'
// import MyContact from './vajefinance/MyAbout.vue'

// 越南petrolivay
// import MyFirst from './petrolivay/MyFirst.vue'
// import MyProtocol from './petrolivay/MyProtocol.vue'
// import MyPaso from './petrolivay/MyService.vue'
// import MyTechno from './petrolivay/MyTechno.vue'
// import MyContact from './petrolivay/MyAbout.vue'

// 越南lestardong
// import MyFirst from './lestardong/MyFirst.vue'
// import MyProtocol from './lestardong/MyProtocol.vue'
// import MyPaso from './lestardong/MyService.vue'
// import MyTechno from './lestardong/MyTechno.vue'
// import MyContact from './lestardong/MyAbout.vue'

// 越南sunvay
// import MyFirst from './sunvay/MyFirst.vue'
// import MyProtocol from './sunvay/MyProtocol.vue'
// import MyPaso from './sunvay/MyPaso.vue'
// import MyQuestions from './sunvay/MyQuestions.vue'
// import MyContact from './sunvay/MyContact.vue'

// 墨西哥paratidinero
// import MyFirst from './paratidinero/MyFirst.vue'
// import MyProtocol from './paratidinero/MyProtocol.vue'
// import MyPaso from './paratidinero/MyService.vue'
// import MyTechno from './paratidinero/MyTechno.vue'
// import MyContact from './paratidinero/MyAbout.vue'
// import MyQuestion from './paratidinero/MyQuestions.vue'

// 越南aitdong
// import MyFirst from './aitdong/MyFirst.vue'
// import MyProtocol from './aitdong/MyProtocol.vue'
// import MyPaso from './aitdong/MyService.vue'
// import MyUser from './aitdong/MyTechno.vue'
// import MyContact from './aitdong/MyAbout.vue'

//泰国microfin2
// import MyFirst from './microfin2/MyFirst.vue'
// import MyProtocol from './microfin2/MyProtocol.vue'
// import MyQuestions from './microfin2/MyQuestions.vue'
// import MyContact from './microfin2/MyAbout.vue'

// 泰国domcap2
// import MyFirst from './domcap2/MyFirst.vue'
// import MyProtocol from './domcap2/MyProtocol.vue'
// import MyPaso from './domcap2/MyService.vue'
// import MyQuestions from './domcap2/MyQuestions.vue'
// import MyContact from './domcap2/MyAbout.vue'

//泰国ngernsaen2
// import MyFirst from './ngernsaen2/MyFirst.vue'
// import MyProtocol from './ngernsaen2/MyProtocol.vue'
// import MyPaso from './ngernsaen2/MyService.vue'
// import MyQuestions from './ngernsaen2/MyQuestions.vue'
// import MyContact from './ngernsaen2/MyAbout.vue'

//越南firefigvay
// import MyFirst from './firefigvay/MyFirst.vue'
// import MyProtocol from './firefigvay/MyProtocol.vue'
// import MyPaso from './firefigvay/MyService.vue'
// import MyQuestions from './firefigvay/MyQuestions.vue'
// import MyContact from './firefigvay/MyAbout.vue'

// 越南sonthuyvay
// import MyFirst from './sonthuyvay/MyFirst.vue'
// import MyProtocol from './sonthuyvay/MyProtocol.vue'
// import MyPaso from './sonthuyvay/MyService.vue'
// import MyQuestions from './sonthuyvay/MyQuestions.vue'
// import MyContact from './sonthuyvay/MyAbout.vue'

// 越南truongtaionline
// import MyFirst from './truongtaionline/MyFirst.vue'
// import MyProtocol from './truongtaionline/MyProtocol.vue'
// import MyPaso from './truongtaionline/MyService.vue'
// import MyQuestions from './truongtaionline/MyQuestions.vue'
// import MyContact from './truongtaionline/MyAbout.vue'

// 越南dhloan
// import MyFirst from './dhloan/MyFirst.vue'
// import MyProtocol from './dhloan/MyProtocol.vue'
// import MyPaso from './dhloan/MyService.vue'
// import MyQuestions from './dhloan/MyQuestions.vue'
// import MyContact from './dhloan/MyAbout.vue'

// 墨西哥janodinero
// import MyFirst from './janodinero/MyFirst.vue'
// import MyProtocol from './janodinero/MyProtocol.vue'
// import MyPaso from './janodinero/MyService.vue'
// import MyQuestions from './janodinero/MyQuestions.vue'
// import MyContact from './janodinero/MyAbout.vue'

// 墨西哥kishonefectivo
// import MyFirst from './kishonefectivo/MyFirst.vue'
// import MyProtocol from './kishonefectivo/MyProtocol.vue'
// import MyCommunity from './kishonefectivo/MyCommunity.vue'
// import MyQuestions from './kishonefectivo/MyQuestions.vue'
// import MyContact from './kishonefectivo/MyAbout.vue'

// 越南longgsloan
// import MyFirst from './longgsloan/MyFirst.vue'
// import MyProtocol from './longgsloan/MyProtocol.vue'
// import MyCommunity from './longgsloan/MyCommunity.vue'
// import MyQuestions from './longgsloan/MyQuestions.vue'
// import MyContact from './longgsloan/MyAbout.vue'

//越南hanh
// import MyFirst from './hanh/MyFirst.vue'
// import MyProtocol from './hanh/MyProtocol.vue'
// import MyCommunity from './hanh/MyCommunity.vue'
// import MyQuestions from './hanh/MyQuestions.vue'
// import MyContact from './hanh/MyAbout.vue'

//泰国subkamolplus
// import MyFirst from './subkamolplus/MyFirst.vue'
// import MyProtocol from './subkamolplus/MyProtocol.vue'
// import MyCommunity from './subkamolplus/MyCommunity.vue'
// import MyQuestions from './subkamolplus/MyQuestions.vue'
// import MyContact from './subkamolplus/MyAbout.vue'

//泰国ktcnano
// import MyFirst from './ktcnano/MyFirst.vue'
// import MyProtocol from './ktcnano/MyProtocol.vue'
// import MyQuestions from './ktcnano/MyQuestions.vue'
// import MyContact from './ktcnano/MyAbout.vue'

//泰国nano
// import MyFirst from './nano/MyFirst.vue'
// import MyProtocol from './nano/MyProtocol.vue'
// import MyService from './nano/MyService.vue'
// import MyAbout from './nano/MyAbout.vue'

// //墨西哥lemonade
// import MyFirst from './lemonade/MyFirst.vue'
// import MyProtocol from './lemonade/MyProtocol.vue'
// import MyPaso from './lemonade/MyService.vue'
// import MyQuestions from './lemonade/MyQuestions.vue'
// import MyContact from './lemonade/MyAbout.vue'

//越南cbmalldong
// import MyFirst from './cbmalldong/MyFirst.vue'
// import MyProtocol from './cbmalldong/MyProtocol.vue'
// import MyPaso from './cbmalldong/MyService.vue'
// import MyQuestions from './cbmalldong/MyQuestions.vue'
// import MyAbout from './cbmalldong/MyAbout.vue'

//越南onsenmobile
// import MyFirst from './onsenmobile/MyFirst.vue'
// import MyProtocol from './onsenmobile/MyProtocol.vue'
// import MyPaso from './onsenmobile/MyService.vue'
// import MyQuestions from './onsenmobile/MyQuestions.vue'
// import MyAbout from './onsenmobile/MyAbout.vue'
// import MyPdf from './onsenmobile/MyPdf.vue'

//墨西哥lordanoupréstamos
// import MyFirst from './lordanoupréstamos/MyFirst.vue'
// import MyProtocol from './lordanoupréstamos/MyProtocol.vue'
// import MyPaso from './lordanoupréstamos/MyService.vue'
// import MyQuestions from './lordanoupréstamos/MyQuestions.vue'
// import MyAbout from './lordanoupréstamos/MyAbout.vue'

//越南lordanoupréstamos2
// import MyFirst from './lordanoupréstamos2/MyFirst.vue'
// import MyProtocol from './lordanoupréstamos2/MyProtocol.vue'
// import MyPaso from './lordanoupréstamos2/MyService.vue'
// import MyQuestions from './lordanoupréstamos2/MyQuestions.vue'
// import MyAbout from './lordanoupréstamos2/MyAbout.vue'

//泰国creditmonitor
// import MyFirst from './creditmonitor/MyFirst.vue'
// import MyProtocol from './creditmonitor/MyProtocol.vue'
// import MyPaso from './creditmonitor/MyService.vue'
// import MyQuestions from './creditmonitor/MyQuestions.vue'
// import MyAbout from './creditmonitor/MyAbout.vue'

//墨西哥mejorandopresta
// import MyFirst from './mejorandopresta/MyFirst.vue'
// import MyProtocol from './mejorandopresta/MyProtocol.vue'
// import MyPaso from './mejorandopresta/MyService.vue'
// import MyQuestions from './mejorandopresta/MyQuestions.vue'
// import MyAbout from './mejorandopresta/MyAbout.vue'

//越南koocheemoney
// import MyFirst from './koocheemoney/MyFirst.vue'
// import MyProtocol from './koocheemoney/MyProtocol.vue'
// import MyPaso from './koocheemoney/MyService.vue'
// import MyQuestions from './koocheemoney/MyQuestions.vue'
// import MyAbout from './koocheemoney/MyAbout.vue'

//墨西哥nitriodinero
// import MyFirst from './nitriodinero/MyFirst.vue'
// import MyProtocol from './nitriodinero/MyProtocol.vue'
// import MyPaso from './nitriodinero/MyService.vue'
// import MyQuestions from './nitriodinero/MyQuestions.vue'
// import MyAbout from './nitriodinero/MyAbout.vue'

//越南nitriodinero2
// import MyFirst from './nitriodinero2/MyFirst.vue'
// import MyProtocol from './nitriodinero2/MyProtocol.vue'
// import MyPaso from './nitriodinero2/MyService.vue'
// import MyQuestions from './nitriodinero2/MyQuestions.vue'
// import MyAbout from './nitriodinero2/MyAbout.vue'

//越南zscredit
// import MyFirst from './zscredit/MyFirst.vue'
// import MyProtocol from './zscredit/MyProtocol.vue'
// import MyService from './zscredit/MyService.vue'
// import MyQuestions from './zscredit/MyQuestions.vue'
// import MyAbout from './zscredit/MyAbout.vue'

// 墨西哥joylendefectivo
// import MyFirst from './joylendefectivo/MyFirst.vue'
// import MyProtocol from './joylendefectivo/MyProtocol.vue'
// import MyPaso from './joylendefectivo/MyService.vue'
// import MyQuestions from './joylendefectivo/MyQuestions.vue'
// import MyContact from './joylendefectivo/MyAbout.vue'

//墨西哥activosmóvil
// import MyFirst from './activosmóvil/MyFirst.vue'
// import MyProtocol from './activosmóvil/MyProtocol.vue'
// import MyQuestions from './activosmóvil/MyQuestions.vue'
// import MyContact from './activosmóvil/MyAbout.vue'
// import MyAboutUs from './activosmóvil/MyAboutUs.vue'
// import MyDevelop from './activosmóvil/MyDevelop.vue'
// import MyMission from './activosmóvil/MyMission.vue'

// 越南giafinance
// import MyFirst from './giafinance/MyFirst.vue'
// import MyProtocol from './giafinance/MyProtocol.vue'
// import MyPaso from './giafinance/MyService.vue'
// import MyQuestions from './giafinance/MyQuestions.vue'
// import MyContact from './giafinance/MyAbout.vue'

// 越南debutvay
// import MyFirst from './debutvay/MyFirst.vue'
// import MyProtocol from './debutvay/MyProtocol.vue'
// import MyPaso from './debutvay/MyService.vue'
// import MyQuestions from './debutvay/MyQuestions.vue'
// import MyContact from './debutvay/MyAbout.vue'
// import MyMission from './debutvay/MyMission.vue'
// import MyDevelop from './debutvay/MyDevelop.vue'

// 墨西哥easycommu
// import MyFirst from './easycommu/MyFirst.vue'
// import MyProtocol from './easycommu/MyProtocol.vue'
// import MyPaso from './easycommu/MyService.vue'
// import MyQuestions from './easycommu/MyQuestions.vue'
// import MyContact from './easycommu/MyAbout.vue'
// import MyMission from './easycommu/MyMission.vue'
// import MyDevelop from './easycommu/MyDevelop.vue'

//泰国sinthorn
// import MyFirst from './sinthorn/MyFirst.vue'
// import MyProtocol from './sinthorn/MyProtocol.vue'
// import MyPaso from './sinthorn/MyService.vue'
// import MyQuestions from './sinthorn/MyQuestions.vue'
// import MyContact from './sinthorn/MyAbout.vue'

//墨西哥kampalacash2
// import MyFirst from './kampalacash2/MyFirst.vue'
// import MyProtocol from './kampalacash2/MyProtocol.vue'
// import MyPaso from './kampalacash2/MyService.vue'
// import MyQuestions from './kampalacash2/MyQuestions.vue'
// import MyContact from './kampalacash2/MyAbout.vue'

//泰国chookiat
// import MyFirst from './chookiat/MyFirst.vue'
// import MyProtocol from './chookiat/MyProtocol.vue'
// import MyPaso from './chookiat/MyService.vue'
// import MyQuestions from './chookiat/MyQuestions.vue'
// import MyContact from './chookiat/MyAbout.vue'

// 越南hathanh
// import MyFirst from './hathanh/MyFirst.vue'
// import MyProtocol from './hathanh/MyProtocol.vue'
// import MyPaso from './hathanh/MyService.vue'
// import MyQuestions from './hathanh/MyQuestions.vue'
// import MyContact from './hathanh/MyAbout.vue'

// 越南hoabinhdong
// import MyFirst from './hoabinhdong/MyFirst.vue'
// import MyProtocol from './hoabinhdong/MyProtocol.vue'
// import MyPaso from './hoabinhdong/MyService.vue'
// import MyQuestions from './hoabinhdong/MyQuestions.vue'
// import MyContact from './hoabinhdong/MyAbout.vue'

// 越南pakvay
// import MyFirst from './pakvay/MyFirst.vue'
// import MyProtocol from './pakvay/MyProtocol.vue'
// import MyPaso from './pakvay/MyService.vue'
// import MyQuestions from './pakvay/MyQuestions.vue'
// import MyContact from './pakvay/MyAbout.vue'

// 墨西哥paraeldinero
// import MyFirst from './paraeldinero/MyFirst.vue'
// import MyProtocol from './paraeldinero/MyProtocol.vue'
// import MyContact from './paraeldinero/MyAbout.vue'

//越南subkamollending
// import MyFirst from './subkamollending/MyFirst.vue'
// import MyProtocol from './subkamollending/MyProtocol.vue'
// import MyCommunity from './subkamollending/MyCommunity.vue'
// import MyQuestions from './subkamollending/MyQuestions.vue'
// import MyContact from './subkamollending/MyAbout.vue'

//墨西哥apoyopresta
// import MyFirst from './apoyopresta/MyFirst.vue'
// import MyProtocol from './apoyopresta/MyProtocol.vue'
// import MyCommunity from './apoyopresta/MyCommunity.vue'
// import MyQuestions from './apoyopresta/MyQuestions.vue'
// import MyContact from './apoyopresta/MyAbout.vue'

//越南namadong
// import MyFirst from './namadong/MyFirst.vue'
// import MyProtocol from './namadong/MyProtocol.vue'
// import MyCommunity from './namadong/MyCommunity.vue'
// import MyQuestions from './namadong/MyQuestions.vue'
// import MyContact from './namadong/MyAbout.vue'

//越南cdrvaytiền
// import MyFirst from './cdrvaytiền/MyFirst.vue'
// import MyProtocol from './cdrvaytiền/MyProtocol.vue'
// import MyCommunity from './cdrvaytiền/MyCommunity.vue'
// import MyQuestions from './cdrvaytiền/MyQuestions.vue'
// import MyContact from './cdrvaytiền/MyAbout.vue'

//墨西哥wisementpresta
// import MyFirst from './wisementpresta/MyFirst.vue'
// import MyProtocol from './wisementpresta/MyProtocol.vue'
// import MyCommunity from './wisementpresta/MyCommunity.vue'
// import MyQuestions from './wisementpresta/MyQuestions.vue'
// import MyContact from './wisementpresta/MyAbout.vue'

//墨西哥urieosmóvil
// import MyFirst from './urieosmóvil/MyFirst.vue'
// import MyProtocol from './urieosmóvil/MyProtocol.vue'
// import MyCommunity from './urieosmóvil/MyCommunity.vue'
// import MyQuestions from './urieosmóvil/MyQuestions.vue'
// import MyContact from './urieosmóvil/MyAbout.vue'
// import MyService from './urieosmóvil/MyService.vue'

//越南thtonline
// import MyFirst from './thtonline/MyFirst.vue'
// import MyProtocol from './thtonline/MyProtocol.vue'
// import MyCommunity from './thtonline/MyCommunity.vue'
// import MyQuestions from './thtonline/MyQuestions.vue'
// import MyContact from './thtonline/MyAbout.vue'

//墨西哥núcleobilletera
// import MyFirst from './núcleobilletera/MyFirst.vue'
// import MyProtocol from './núcleobilletera/MyProtocol.vue'
// import MyCommunity from './núcleobilletera/MyCommunity.vue'
// import MyQuestions from './núcleobilletera/MyQuestions.vue'
// import MyContact from './núcleobilletera/MyAbout.vue'

//墨西哥oportunifyloan
// import MyFirst from './oportunifyloan/MyFirst.vue'
// import MyProtocol from './oportunifyloan/MyProtocol.vue'
// import MyCommunity from './oportunifyloan/MyCommunity.vue'
// import MyQuestions from './oportunifyloan/MyQuestions.vue'
// import MyContact from './oportunifyloan/MyAbout.vue'

//越南namdomobile
// import MyFirst from './namdomobile/MyFirst.vue'
// import MyProtocol from './namdomobile/MyProtocol.vue'
// import MyCommunity from './namdomobile/MyCommunity.vue'
// import MyQuestions from './namdomobile/MyQuestions.vue'
// import MyContact from './namdomobile/MyAbout.vue'

//越南manhtienvay
// import MyFirst from './manhtienvay/MyFirst.vue'
// import MyProtocol from './manhtienvay/MyProtocol.vue'
// import MyCommunity from './manhtienvay/MyCommunity.vue'
// import MyQuestions from './manhtienvay/MyQuestions.vue'
// import MyContact from './manhtienvay/MyAbout.vue'

//越南artemisdong
// import MyFirst from './artemisdong/MyFirst.vue'
// import MyProtocol from './artemisdong/MyProtocol.vue'
// import MyPaso from './artemisdong/MyService.vue'
// import MyQuestions from './artemisdong/MyQuestions.vue'
// import MyContact from './artemisdong/MyAbout.vue'

// 越南huyenvay
// import MyFirst from './huyenvay/MyFirst.vue'
// import MyProtocol from './huyenvay/MyProtocol.vue'
// import MyPaso from './huyenvay/MyService.vue'
// import MyQuestions from './huyenvay/MyQuestions.vue'
// import MyContact from './huyenvay/MyAbout.vue'

//墨西哥baranpago
// import MyFirst from './baranpago/MyFirst.vue'
// import MyProtocol from './baranpago/MyProtocol.vue'
// import MyPaso from './baranpago/MyService.vue'
// import MyQuestions from './baranpago/MyQuestions.vue'
// import MyContact from './baranpago/MyAbout.vue'

// 墨西哥tongshiimportacion
// import MyFirst from './tongshiimportacion/MyFirst.vue'
// import MyProtocol from './tongshiimportacion/MyProtocol.vue'
// import MyPaso from './tongshiimportacion/MyService.vue'
// import MyQuestions from './tongshiimportacion/MyQuestions.vue'
// import MyContact from './tongshiimportacion/MyAbout.vue'

//墨西哥coparfindinero
// import MyFirst from './coparfindinero/MyFirst.vue'
// import MyProtocol from './coparfindinero/MyProtocol.vue'
// import MyPaso from './coparfindinero/MyService.vue'
// import MyQuestions from './coparfindinero/MyQuestions.vue'
// import MyContact from './coparfindinero/MyAbout.vue'

//越南pacwallet
// import MyFirst from './pacwallet/MyFirst.vue'
// import MyProtocol from './pacwallet/MyProtocol.vue'
// import MyPaso from './pacwallet/MyService.vue'
// import MyQuestions from './pacwallet/MyQuestions.vue'
// import MyContact from './pacwallet/MyAbout.vue'

// 越南quocvay
// import MyFirst from './quocvay/MyFirst.vue'
// import MyProtocol from './quocvay/MyProtocol.vue'
// import MyPaso from './quocvay/MyService.vue'
// import MyQuestions from './quocvay/MyQuestions.vue'
// import MyContact from './quocvay/MyAbout.vue'

//越南quangcash
// import MyFirst from './quangcash/MyFirst.vue'
// import MyProtocol from './quangcash/MyProtocol.vue'
// import MyPaso from './quangcash/MyService.vue'
// import MyQuestions from './quangcash/MyQuestions.vue'
// import MyContact from './quangcash/MyAbout.vue'

// 越南tcamvay
// import MyFirst from './tcamvay/MyFirst.vue'
// import MyProtocol from './tcamvay/MyProtocol.vue'
// import MyPaso from './tcamvay/MyService.vue'
// import MyQuestions from './tcamvay/MyQuestions.vue'
// import MyContact from './tcamvay/MyAbout.vue'

//墨西哥confianegocios
// import MyFirst from './confianegocios/MyFirst.vue'
// import MyAbout from './confianegocios/MyAbout.vue'
// import MyInformation from './confianegocios/MyInformation.vue'
// import MySupport from './confianegocios/MySupport.vue'
// import MyProtocol from './confianegocios/MyProtocol.vue'

//墨西哥animoparacrecer
// import MyFirst from './animoparacrecer/MyFirst.vue'
// import MyAbout from './animoparacrecer/MyAbout.vue'
// import MyInformation from './animoparacrecer/MyInformation.vue'
// import MySupport from './animoparacrecer/MySupport.vue'
// import MyProtocol from './animoparacrecer/MyProtocol.vue'

//墨西哥agorarecursos
// import MyFirst from './agorarecursos/MyFirst.vue'
// import MyIntroduce from './agorarecursos/MyIntroduce.vue'
// import MyQuestions from './agorarecursos/MyQuestions.vue'
// import MyProtocol from './agorarecursos/MyProtocol.vue'

//泰国kwamdeebusiness
// import MyFirst from './kwamdeebusiness/MyFirst.vue'
// import MyIntroduce from './kwamdeebusiness/MyIntroduce.vue'
// import MyQuestions from './kwamdeebusiness/MyQuestions.vue'
// import MyProtocol from './kwamdeebusiness/MyProtocol.vue'

//泰国jengtimber
// import MyFirst from './jengtimber/MyFirst.vue'
// import MyIntroduce from './jengtimber/MyIntroduce.vue'
// import MyQuestions from './jengtimber/MyQuestions.vue'
// import MyProtocol from './jengtimber/MyProtocol.vue'

//越南anhitinformation
// import MyFirst from './anhitinformation/MyFirst.vue'
// import MyAbout from './anhitinformation/MyAbout.vue'
// import MyQuestions from './anhitinformation/MyQuestions.vue'
// import MyProtocol from './anhitinformation/MyProtocol.vue'

//越南hungdaianapp
// import MyFirst from './hungdaianapp/MyFirst.vue'
// import MyAbout from './hungdaianapp/MyAbout.vue'
// import MyQuestions from './hungdaianapp/MyQuestions.vue'
// import MyProtocol from './hungdaianapp/MyProtocol.vue'

//越南donghaisoftware
// import MyFirst from './donghaisoftware/MyFirst.vue'
// import MyAbout from './donghaisoftware/MyAbout.vue'
// import MyQuestions from './donghaisoftware/MyQuestions.vue'
// import MyProtocol from './donghaisoftware/MyProtocol.vue'

//越南tandatphatmoney
// import MyFirst from './tandatphatmoney/MyFirst.vue'
// import MyAbout from './tandatphatmoney/MyAbout.vue'
// import MyQuestions from './tandatphatmoney/MyQuestions.vue'
// import MyProtocol from './tandatphatmoney/MyProtocol.vue'

//越南smartiesconsulting
// import MyFirst from './smartiesconsulting/MyFirst.vue'
// import MyAbout from './smartiesconsulting/MyAbout.vue'
// import MyQuestions from './smartiesconsulting/MyQuestions.vue'
// import MyProtocol from './smartiesconsulting/MyProtocol.vue'

//越南lvsoftcompanylimited
// import MyFirst from './lvsoftcompanylimited/MyFirst.vue'
// import MyIntroduce from './lvsoftcompanylimited/MyIntroduce.vue'
// import MyQuestions from './lvsoftcompanylimited/MyQuestions.vue'
// import MyProtocol from './lvsoftcompanylimited/MyProtocol.vue'

//墨西哥donapoyo
// import MyFirst from './donapoyo/MyFirst.vue'
// import MyHistory from './donapoyo/MyHistory.vue'
// import MyQuestions from './donapoyo/MyQuestions.vue'
// import MyProtocol from './donapoyo/MyProtocol.vue'

//泰国vcwealthloan
// import MyFirst from './vcwealthloan/MyFirst.vue'
// import MyIntroduce from './vcwealthloan/MyIntroduce.vue'
// import MyQuestions from './vcwealthloan/MyQuestions.vue'
// import MyProtocol from './vcwealthloan/MyProtocol.vue'

//墨西哥inmediprest
// import MyFirst from './inmediprest/MyFirst.vue'
// import MyAbout from './inmediprest/MyAbout.vue'
// import MyIntroduce from './inmediprest/MyIntroduce.vue'
// import MyQuestions from './inmediprest/MyQuestions.vue'
// import MyProtocol from './inmediprest/MyProtocol.vue'

//墨西哥foniclub
// import MyFirst from './foniclub/MyFirst.vue'
// import MyIntroduce from './foniclub/MyIntroduce.vue'
// import MyAbout from './foniclub/MyAbout.vue'
// import MyProtocol from './foniclub/MyProtocol.vue'

//墨西哥alorsi
// import MyFirst from './alorsi/MyFirst.vue'
// import MyIntroduce from './alorsi/MyIntroduce.vue'
// import MyAbout from './alorsi/MyAbout.vue'
// import MyProtocol from './alorsi/MyProtocol.vue'

//越南bonodilecold
// import MyFirst from './bonodilecold/MyFirst.vue'
// import MyIntroduce from './bonodilecold/MyIntroduce.vue'
// import MyQuestions from './bonodilecold/MyQuestions.vue'
// import MyProtocol from './bonodilecold/MyProtocol.vue'

//泰国mitrsinduan
// import MyFirst from './mitrsinduan/MyFirst.vue'
// import MyIntroduce from './mitrsinduan/MyIntroduce.vue'
// import MyQuestions from './mitrsinduan/MyQuestions.vue'
// import MyProtocol from './mitrsinduan/MyProtocol.vue'
// import MyBOT from './mitrsinduan/MyBOT.vue'

//墨西哥generandorlq
// import MyFirst from './generandorlq/MyFirst.vue'
// import MyAbout from './generandorlq/MyAbout.vue'
// import MyQuestions from './generandorlq/MyQuestions.vue'
// import MyProtocol from './generandorlq/MyProtocol.vue'

//墨西哥zchogorcredito
// import MyFirst from './zchogorcredito/MyFirst.vue'
// import MyAbout from './zchogorcredito/MyAbout.vue'
// import MyQuestions from './zchogorcredito/MyQuestions.vue'
// import MyProtocol from './zchogorcredito/MyProtocol.vue'

//墨西哥conficreosofom
// import MyFirst from './conficreosofom/MyFirst.vue'
// import MyAbout from './conficreosofom/MyAbout.vue'
// import MyQuestions from './conficreosofom/MyQuestions.vue'
// import MyProtocol from './conficreosofom/MyProtocol.vue'

//越南dreamcashddthanh
// import MyFirst from './dreamcashddthanh/MyFirst.vue'
// import MyAbout from './dreamcashddthanh/MyAbout.vue'
// import MyQuestions from './dreamcashddthanh/MyQuestions.vue'
// import MyProtocol from './dreamcashddthanh/MyProtocol.vue'

//越南chickenfiretown
// import MyFirst from './chickenfiretown/MyFirst.vue'
// import MyAbout from './chickenfiretown/MyAbout.vue'
// import MyQuestions from './chickenfiretown/MyQuestions.vue'
// import MyProtocol from './chickenfiretown/MyProtocol.vue'

//越南mangomoneyapp
// import MyFirst from './mangomoneyapp/MyFirst.vue'
// import MyAbout from './mangomoneyapp/MyAbout.vue'
// import MyQuestions from './mangomoneyapp/MyQuestions.vue'
// import MyProtocol from './mangomoneyapp/MyProtocol.vue'

//越南maybaengcompanylimitedapp
// import MyFirst from './maybaengcompanylimitedapp/MyFirst.vue'
// import MyAbout from './maybaengcompanylimitedapp/MyAbout.vue'
// import MyQuestions from './maybaengcompanylimitedapp/MyQuestions.vue'
// import MyProtocol from './maybaengcompanylimitedapp/MyProtocol.vue'

//墨西哥kartermax
// import MyFirst from './kartermax/MyFirst.vue'
// import MyAbout from './kartermax/MyAbout.vue'
// import MyQuestions from './kartermax/MyQuestions.vue'
// import MyProtocol from './kartermax/MyProtocol.vue'

//越南hauthuycompanylid
// import MyFirst from './hauthuycompanylid/MyFirst.vue'
// import MyAbout from './hauthuycompanylid/MyAbout.vue'
// import MyQuestions from './hauthuycompanylid/MyQuestions.vue'
// import MyProtocol from './hauthuycompanylid/MyProtocol.vue'

//越南hanvanthuycoldapp
// import MyFirst from './hanvanthuycoldapp/MyFirst.vue'
// import MyAbout from './hanvanthuycoldapp/MyAbout.vue'
// import MyQuestions from './hanvanthuycoldapp/MyQuestions.vue'
// import MyProtocol from './hanvanthuycoldapp/MyProtocol.vue'

//墨西哥fidecreo
// import MyFirst from './fidecreo/MyFirst.vue'
// import MyIntroduce from './fidecreo/MyIntroduce.vue'
// import MyAbout from './fidecreo/MyAbout.vue'
// import MyProtocol from './fidecreo/MyProtocol.vue'

//墨西哥ttbluescapital
// import MyFirst from './ttbluescapital/MyFirst.vue'
// import MyAbout from './ttbluescapital/MyAbout.vue'
// import MyQuestions from './ttbluescapital/MyQuestions.vue'
// import MyProtocol from './ttbluescapital/MyProtocol.vue'

//泰国aira-aiful
// import MyFirst from './aira-aiful/MyFirst.vue'
// import MyAbout from './aira-aiful/MyAbout.vue'
// import MyQuestions from './aira-aiful/MyQuestions.vue'
// import MyProtocol from './aira-aiful/MyProtocol.vue'

//越南thaidinhphatapp
// import MyFirst from './thaidinhphatapp/MyFirst.vue'
// import MyAbout from './thaidinhphatapp/MyAbout.vue'
// import MyQuestions from './thaidinhphatapp/MyQuestions.vue'
// import MyProtocol from './thaidinhphatapp/MyProtocol.vue'

//越南speedyloanapp
// import MyFirst from './speedyloanapp/MyFirst.vue'
// import MyAbout from './speedyloanapp/MyAbout.vue'
// import MyQuestions from './speedyloanapp/MyQuestions.vue'
// import MyProtocol from './speedyloanapp/MyProtocol.vue'

//越南happycreditapp
// import MyFirst from './happycreditapp/MyFirst.vue'
// import MyAbout from './happycreditapp/MyAbout.vue'
// import MyQuestions from './happycreditapp/MyQuestions.vue'
// import MyProtocol from './happycreditapp/MyProtocol.vue'

//越南financialcompanionlvay
// import MyFirst from './financialcompanionlvay/MyFirst.vue'
// import MyAbout from './financialcompanionlvay/MyAbout.vue'
// import MyQuestions from './financialcompanionlvay/MyQuestions.vue'
// import MyProtocol from './financialcompanionlvay/MyProtocol.vue'

//墨西哥agroatoto
// import MyFirst from './agroatoto/MyFirst.vue'
// import MyAbout from './agroatoto/MyAbout.vue'
// import MyQuestions from './agroatoto/MyQuestions.vue'
// import MyProtocol from './agroatoto/MyProtocol.vue'

//越南loanwaveapp
// import MyFirst from './loanwaveapp/MyFirst.vue'
// import MyAbout from './loanwaveapp/MyAbout.vue'
// import MyQuestions from './loanwaveapp/MyQuestions.vue'
// import MyProtocol from './loanwaveapp/MyProtocol.vue'

//印尼kspbawanaartha
// import MyFirst from './kspbawanaartha/MyFirst.vue'
// import MyAbout from './kspbawanaartha/MyAbout.vue'
// import MyIntroduce from './kspbawanaartha/MyIntroduce.vue'
// import MyProtocol from './kspbawanaartha/MyProtocol.vue'

//越南duongvuongtcomld
// import MyFirst from './duongvuongtcomld/MyFirst.vue'
// import MyAbout from './duongvuongtcomld/MyAbout.vue'
// import MyQuestions from './duongvuongtcomld/MyQuestions.vue'
// import MyProtocol from './duongvuongtcomld/MyProtocol.vue'

//泰国ngoentoryodcompany
// import MyFirst from './ngoentoryodcompany/MyFirst.vue'
// import MyAbout from './ngoentoryodcompany/MyAbout.vue'
// import MyQuestions from './ngoentoryodcompany/MyQuestions.vue'
// import MyProtocol from './ngoentoryodcompany/MyProtocol.vue'

//泰国worldleasecompany
// import MyFirst from './worldleasecompany/MyFirst.vue'
// import MyAbout from './worldleasecompany/MyAbout.vue'
// import MyQuestions from './worldleasecompany/MyQuestions.vue'
// import MyProtocol from './worldleasecompany/MyProtocol.vue'

//墨西哥ipesofom
// import MyFirst from './ipesofom/MyFirst.vue'
// import MyAbout from './ipesofom/MyAbout.vue'
// import MyIntroduce from './ipesofom/MyIntroduce.vue'
// import MyProtocol from './ipesofom/MyProtocol.vue'

//越南mekongdevelopmentbank
// import MyFirst from './mekongdevelopmentbank/MyFirst.vue'
// import MyAbout from './mekongdevelopmentbank/MyAbout.vue'
// import MyQuestions from './mekongdevelopmentbank/MyQuestions.vue'
// import MyProtocol from './mekongdevelopmentbank/MyProtocol.vue'

//墨西哥theoutdoorsfinancil
// import MyFirst from './theoutdoorsfinancil/MyFirst.vue'
// import MyAbout from './theoutdoorsfinancil/MyAbout.vue'
// import MyIntroduce from './theoutdoorsfinancil/MyIntroduce.vue'
// import MyProtocol from './theoutdoorsfinancil/MyProtocol.vue'

//越南prosperityandgrowthcommercialjointbank
// import MyFirst from './prosperityandgrowthcommercialjointbank/MyFirst.vue'
// import MyAbout from './prosperityandgrowthcommercialjointbank/MyAbout.vue'
// import MyQuestions from './prosperityandgrowthcommercialjointbank/MyQuestions.vue'
// import MyProtocol from './prosperityandgrowthcommercialjointbank/MyProtocol.vue'

//越南mongcaitokyoeahhtjsc
// import MyFirst from './mongcaitokyoeahhtjsc/MyFirst.vue'
// import MyAbout from './mongcaitokyoeahhtjsc/MyAbout.vue'
// import MyQuestions from './mongcaitokyoeahhtjsc/MyQuestions.vue'
// import MyProtocol from './mongcaitokyoeahhtjsc/MyProtocol.vue'

//墨西哥cherreyprestamos
// import MyFirst from './cherreyprestamos/MyFirst.vue'
// import MyAbout from './cherreyprestamos/MyAbout.vue'
// import MyIntroduce from './cherreyprestamos/MyIntroduce.vue'
// import MyProtocol from './cherreyprestamos/MyProtocol.vue'

//越南quanbinhidbank
// import MyFirst from './quanbinhidbank/MyFirst.vue'
// import MyAbout from './quanbinhidbank/MyAbout.vue'
// import MyQuestions from './quanbinhidbank/MyQuestions.vue'
// import MyProtocol from './quanbinhidbank/MyProtocol.vue'

//泰国seamoneycompany
// import MyFirst from './seamoneycompany/MyFirst.vue'
// import MyAbout from './seamoneycompany/MyAbout.vue'
// import MyQuestions from './seamoneycompany/MyQuestions.vue'
// import MyProtocol from './seamoneycompany/MyProtocol.vue'

//越南maritimebankassetmanagement
// import MyFirst from './maritimebankassetmanagement/MyFirst.vue'
// import MyAbout from './maritimebankassetmanagement/MyAbout.vue'
// import MyQuestions from './maritimebankassetmanagement/MyQuestions.vue'
// import MyProtocol from './maritimebankassetmanagement/MyProtocol.vue'

//越南vietcomlimitedcompany
// import MyFirst from './vietcomlimitedcompany/MyFirst.vue'
// import MyAbout from './vietcomlimitedcompany/MyAbout.vue'
// import MyQuestions from './vietcomlimitedcompany/MyQuestions.vue'
// import MyProtocol from './vietcomlimitedcompany/MyProtocol.vue'

//越南bengreenvietnamcompanylimited
// import MyFirst from './bengreenvietnamcompanylimited/MyFirst.vue'
// import MyAbout from './bengreenvietnamcompanylimited/MyAbout.vue'
// import MyQuestions from './bengreenvietnamcompanylimited/MyQuestions.vue'
// import MyProtocol from './bengreenvietnamcompanylimited/MyProtocol.vue'

//越南emdaycompanylimited
// import MyFirst from './emdaycompanylimited/MyFirst.vue'
// import MyAbout from './emdaycompanylimited/MyAbout.vue'
// import MyQuestions from './emdaycompanylimited/MyQuestions.vue'
// import MyProtocol from './emdaycompanylimited/MyProtocol.vue'

//越南lvsoftloamapp
// import MyFirst from './lvsoftloamapp/MyFirst.vue'
// import MyIntroduce from './lvsoftloamapp/MyIntroduce.vue'
// import MyQuestions from './lvsoftloamapp/MyQuestions.vue'
// import MyProtocol from './lvsoftloamapp/MyProtocol.vue'

//墨西哥bmtservicios
// import MyFirst from './bmtservicios/MyFirst.vue'
// import MyAbout from './bmtservicios/MyAbout.vue'
// import MyIntroduce from './bmtservicios/MyIntroduce.vue'
// import MyProtocol from './bmtservicios/MyProtocol.vue'

//泰国creditcheck-ktcnanowallet
// import MyFirst from './creditcheck-ktcnanowallet/MyFirst.vue'
// import MyAbout from './creditcheck-ktcnanowallet/MyAbout.vue'
// import MyProtocol from './creditcheck-ktcnanowallet/MyProtocol.vue'

//越南dreamvayapploan
// import MyFirst from './dreamvayapploan/MyFirst.vue'
// import MyAbout from './dreamvayapploan/MyAbout.vue'
// import MyQuestions from './dreamvayapploan/MyQuestions.vue'
// import MyProtocol from './dreamvayapploan/MyProtocol.vue'

//墨西哥ceibamovilmx-ficreo
// import MyFirst from './ceibamovilmx-ficreo/MyFirst.vue'
// import MyAbout from './ceibamovilmx-ficreo/MyAbout.vue'
// import MyIntroduce from './ceibamovilmx-ficreo/MyIntroduce.vue'
// import MyProtocol from './ceibamovilmx-ficreo/MyProtocol.vue'

//墨西哥desarrolloorigenesfofor
// import MyFirst from './desarrolloorigenesfofor/MyFirst.vue'
// import MyAbout from './desarrolloorigenesfofor/MyAbout.vue'
// import MyIntroduce from './desarrolloorigenesfofor/MyIntroduce.vue'
// import MyProtocol from './desarrolloorigenesfofor/MyProtocol.vue'

//墨西哥distribuidoradecision
// import MyFirst from './distribuidoradecision/MyFirst.vue'
// import MyAbout from './distribuidoradecision/MyAbout.vue'
// import MyQuestions from './distribuidoradecision/MyQuestions.vue'
// import MyProtocol from './distribuidoradecision/MyProtocol.vue'

//墨西哥lithoimapcolor
// import MyFirst from './lithoimapcolor/MyFirst.vue'
// import MyAbout from './lithoimapcolor/MyAbout.vue'
// import MyQuestions from './lithoimapcolor/MyQuestions.vue'
// import MyProtocol from './lithoimapcolor/MyProtocol.vue'

//越南thanhquanggeneralcompany
import MyFirst from './thanhquanggeneralcompany/MyFirst.vue'
import MyAbout from './thanhquanggeneralcompany/MyAbout.vue'
import MyQuestions from './thanhquanggeneralcompany/MyQuestions.vue'
import MyProtocol from './thanhquanggeneralcompany/MyProtocol.vue'

Vue.use(VueRouter);
//越南namvaytien
// const routes = [
//     {
//       path: '/',
//       component: MyFirst, // 默认路由
//     },
//     {
//       path: '/questions',
//       component: MyQuestion, // 默认路由
//     },
//     {
//       path: '/protocol',
//       component: MyProtocol, // product 路由
//     },
//     {
//         path: '/about',
//         component: MyAbout, // /list 路由
//       },

//   ]

//泰国chatchaimobile
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },

//   {
//     path: '/protocol',
//     component: MyProtocol, // protocol 路由
//   },
// ]

//泰国jaidee
// const routes = [
//     {
//       path: '/',
//       component: MyFirst, // 默认路由
//     },
//     {
//       path: '/questions',
//       component: MyQuestion, // 默认路由
//     },
//     {
//       path: '/protocol',
//       component: MyProtocol, // product 路由
//     },
//     {
//         path: '/service',
//         component: MyService, // /list 路由
//       },

//   ]
//泰国第二个jaidee
// const routes = [
//     {
//       path: '/',
//       component: MyFirst, // 默认路由
//     },
//     {
//       path: '/clause',
//       component: MyClause, // 默认路由
//     },
//     {
//       path: '/protocol',
//       component: MyProtocol, // product 路由
//     },
//     {
//         path: '/about',
//         component: MyAbout, // /list 路由
//       },

//   ]
//越南mrVaymobile
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/contact',
//     component: MyContact, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/about',
//       component: MyAbout, // /list 路由
//     },

// ]

//越南kasikvay
// const routes = [
//     {
//       path: '/',
//       component: MyFirst, // 默认路由
//     },
//     {
//       path: '/about',
//       component: MyAbout, // /list 路由
//     },

//     {
//       path: '/clause',
//       component: MyClause, // 默认路由
//     },
//     {
//       path: '/questions',
//       component: MyQuestions, // /list 路由
//     },
//     {
//       path: '/protocol',
//       component: MyProtocol, // product 路由
//     },

//   ]

//墨西哥tamogiefectivo
// const routes = [
//   {
//     path: "/",
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: "/questions",
//     component: MyQuestion, // 默认路由
//   },
//   {
//     path: "/protocol",
//     component: MyProtocol, // product 路由
//   },
//   {
//     path: "/about",
//     component: MyAbout, // /list 路由
//   },
//   {
//     path: "/center",
//     component: MyCenter, // /list 路由
//   },
// ];

//墨西哥consedeefectivo
// const routes = [
//     {
//       path: '/',
//       component: MyFirst, // 默认路由
//     },
//     {
//       path: '/about',
//       component: MyAbout, // /list 路由
//     },

//     {
//       path: '/service',
//       component: MyService, // /list 路由
//     },
//     {
//       path: '/protocol',
//       component: MyProtocol, // product 路由
//     },

//   ]

// 越南betacreditdinero
// const routes = [
//     {
//       path: '/',
//       component: MyFirst, // 默认路由
//     },
//     {
//       path: '/about',
//       component: MyAbout, // /list 路由
//     },

//     {
//       path: '/contant',
//       component: MyContact, // /list 路由
//     },
//     {
//       path: '/protocol',
//       component: MyProtocol, // product 路由
//     },

//   ]
// 越南nathcopay
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // /list 路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },

// ]

// 越南hcthlogplus
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/about',
//     component: MyAbout, // /list 路由
//   },
//   {
//     path: '/process',
//     component: MyProcess, // /list 路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },

// ]

//
//墨西哥hipotecariabilletera
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/process',
//     component: MyProcess, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/paso',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/contact',
//       component: MyContact, // /list 路由
//     },
//     {
//       path: '/contrato',
//       component: MyContrato, // /list 路由
//     },
// ]

//越南sunmobile
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/paso',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/contact',
//       component: MyContact, // /list 路由
//     },
// ]

//越南cathaydonge
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },

//     {
//       path: '/service',
//       component: MyService, // /list 路由
//     },
// ]

//越南newvay
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/about',
//       component: MyAbout, // /list 路由
//     },
//     {
//       path: '/service',
//       component: MyService, // /list 路由
//     },
// ]

//墨西哥eldefiardinero
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/support',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/news',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyAbout, // /list 路由
//     },
// ]

//越南thanhchungplus
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/support',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/news',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyAbout, // /list 路由
//     },
// ]

//越南Dealcorp Efectivo
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },

//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyService, // /list 路由
//     },
//     {
//       path: '/contact',
//       component: MyContact, // /list 路由
//     },
// ]

//墨西哥kampalacash
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },

//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyService, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyAbout, // /list 路由
//     },
// ]

//泰国pheera
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/contact',
//       component: MyContact, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyAbout, // /list 路由
//     },
// ]

//越南piwebplus
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
// ]

//越南piwebplus2
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
// ]

//墨西哥directodoefectivo
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
// ]

//泰国Jaideeonline
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
// ]

//墨西哥braskemefectivo
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
// ]

//墨西哥dealcorpefectivo2
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/contact',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/product',
//       component: MyContact, // /list 路由
//     },
// ]

//墨西哥ceibamóvil
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
//     {
//       path: '/community',
//       component: MyCommunity, // /list 路由
//     },

// ]

//墨西哥estimulocrédito
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
//     {
//       path: '/help',
//       component: MyHelp, // /list 路由
//     },
//     {
//       path: '/process',
//       component: MyProcess, // /list 路由
//     },
//     {
//       path: '/condition',
//       component: MyCondition, // /list 路由
//     },

// ]
//墨西哥cincodospréstamos
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
//     {
//       path: '/community',
//       component: MyCommunity, // /list 路由
//     },
//     {
//       path: '/tips',
//       component: MyTips, // /list 路由
//     },

// ]

//墨西哥concreecefectivo
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
//     {
//       path: '/community',
//       component: MyCommunity, // /list 路由
//     },
//     {
//       path: '/questions',
//       component: MyQuestions, // /list 路由
//     },

// ]

//墨西哥daraptipago和wiicredi
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
// ]

//泰国microfinmobile
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
//     {
//       path: '/community',
//       component: MyCommunity, // /list 路由
//     },

// ]

//墨西哥ammóvil
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
// ]

//墨西哥ngernsaen
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
// ]

//泰国domcap
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
// ]

//墨西哥cherr2||operadoracredito
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyService, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyAbout, // /list 路由
//     },
//     {
//       path: '/help',
//       component: MyHelp, // /list 路由
//     },
//     {
//       path: '/user',
//       component: MyProtocol2, // /list 路由
//     },
// ]

//墨西哥facicasapresta
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyService, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyAbout, // /list 路由
//     },
// ]

//墨西哥eliteloan
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
// ]

//越南drkinhtiềnbạc
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/techno',
//     component: MyTechno, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/protocol',
//       component: MyContact, // /list 路由
//     },
// ]

//越南vajefinance
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/techno',
//     component: MyTechno, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/protocol',
//       component: MyContact, // /list 路由
//     },
// ]

//越南petrolivay
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/news',
//     component: MyTechno, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
// ]

//越南lestardong
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/news',
//     component: MyTechno, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/help',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
// ]

//越南sunvay
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/paso',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/contact',
//       component: MyContact, // /list 路由
//     },
// ]

//墨西哥paratidinero
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/news',
//     component: MyTechno, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/help',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
//     {
//       path: '/questions',
//       component: MyQuestion, // /list 路由
//     },
// ]

//越南aitdong
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/user',
//     component: MyUser, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
// ]

//泰国microfin2
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },

//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/questions',
//       component: MyQuestions, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },

// ]

//泰国domcap2
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/help',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
// ]

//泰国ngernsaen2
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
// ]

//越南firefigvay
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/help',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
// ]

//越南sonthuyvay
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
// ]

//越南truongtaionline
// const routes = [
//   {
//     path: '/',
//     name: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/about',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/user',
//       component: MyContact, // /list 路由
//     },
// ]
//越南dhloan
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
// ]

//墨西哥janodinero
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
// ]

//墨西哥kishonefectivo
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/contact',
//       component: MyCommunity, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
// ]

//越南longgsloan
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/contact',
//       component: MyCommunity, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
// ]

//越南hanh
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/contact',
//       component: MyCommunity, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
// ]

//泰国subkamolplus
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/contact',
//       component: MyCommunity, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
// ]

//泰国ktcnano
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/story',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },

//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
// ]

// 泰国nano
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/about',
//     component: MyAbout, // product 路由
//   },
//   {
//     path: '/service',
//     component: MyService, // product 路由
//   },
//     {
//       path: '/protocol',
//       component: MyProtocol, // /list 路由
//     },
// ]

//墨西哥lemonade
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/contract',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/about',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/user',
//       component: MyContact, // /list 路由
//     },
// ]

//越南cbmalldong
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/structure',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/introduce',
//       component: MyAbout, // /list 路由
//     },
// ]

//越南onsenmobile
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyAbout, // /list 路由
//     },
//     {
//       path: '/pdf',
//       component: MyPdf, // /list 路由
//     },
// ]

//墨西哥lordanoupréstamos
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyAbout, // /list 路由
//     },
// ]

//越南lordanoupréstamos2
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyAbout, // /list 路由
//     },
// ]

//泰国creditmonitor
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyAbout, // /list 路由
//     },
// ]

// //墨西哥mejorandopresta
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyAbout, // /list 路由
//     },
// ]

//越南koocheemoney
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyAbout, // /list 路由
//     },
// ]

//墨西哥nitriodinero
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyAbout, // /list 路由
//     },
// ]

//越南nitriodinero2
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyAbout, // /list 路由
//     },
// ]

//越南zscredit
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/product',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyService, // /list 路由
//     },
//     {
//       path: '/news',
//       component: MyAbout, // /list 路由
//     },
// ]

//墨西哥joylendefectivo
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
// ]

//墨西哥activosmóvil
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },

//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/questions',
//       component: MyQuestions, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
//     {
//       path: '/about_us',
//       component: MyAboutUs, // /list 路由
//     },
//     {
//       path: '/develop',
//       component: MyDevelop, // /list 路由
//     },
//     {
//       path: '/mission',
//       component:  MyMission, // /list 路由
//     },
// ]

//越南giafinance
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
// ]

//越南debutvay
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
//     {
//       path: '/mission',
//       component: MyMission, // /list 路由
//     },
//     {
//       path: '/develop',
//       component: MyDevelop, // /list 路由
//     },
// ]

//墨西哥easycommu
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
//     {
//       path: '/mission',
//       component: MyMission, // /list 路由
//     },
//     {
//       path: '/develop',
//       component: MyDevelop, // /list 路由
//     },
// ]

//墨西哥kampalacash2
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
// ]

//墨西哥kampalacash
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
// ]

//泰国chookiat
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
// ]

//越南hathanh
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
// ]

//越南hoabinhdong
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
// ]

//越南pakvay
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
// ]

//墨西哥paraeldinero
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
// ]

//越南subkamollending
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/contact',
//       component: MyCommunity, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
// ]

//墨西哥apoyopresta
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/contact',
//       component: MyCommunity, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
// ]

//越南namadong
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/contact',
//       component: MyCommunity, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
// ]

//越南cdrvaytiền
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/contact',
//       component: MyCommunity, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
// ]

//墨西哥wisementpresta
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/contact',
//       component: MyCommunity, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
// ]

//墨西哥urieosmóvil
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/contact',
//       component: MyCommunity, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
//     {
//       path: '/service',
//       component: MyService, // /list 路由
//     },
// ]

//越南thtonline
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/contact',
//       component: MyCommunity, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
// ]

//墨西哥núcleobilletera
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/contact',
//       component: MyCommunity, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
// ]

//墨西哥oportunifyloan
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/contact',
//       component: MyCommunity, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
// ]

//越南namdomobile
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/contact',
//       component: MyCommunity, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
// ]

//越南manhtienvay
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/contact',
//       component: MyCommunity, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
// ]

//越南artemisdong
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
// ]

//越南huyenvay
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
// ]

//墨西哥baranpago
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
// ]

//墨西哥tongshiimportacion
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
// ]

//墨西哥coparfindinero
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
// ]

//越南pacwallet
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
// ]

//越南quocvay
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
// ]

//越南quangcash
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
// ]

//越南tcamvay
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
// ]

//墨西哥confianegocios
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/about',
//     component: MyAbout, // about 路由
//   },
//   {
//     path: '/information',
//     component: MyInformation, // information 路由
//   },
//   {
//     path: '/support',
//     component: MySupport, // support 路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // protocol 路由
//   },
// ]

//墨西哥animoparacrecer
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/about',
//     component: MyAbout, // about 路由
//   },
//   {
//     path: '/information',
//     component: MyInformation, // information 路由
//   },
//   {
//     path: '/support',
//     component: MySupport, // support 路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // protocol 路由
//   },
// ]

//墨西哥agorarecursos
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/introduce',
//     component: MyIntroduce, // introduce 路由
//   },
//   {
//     path: '/question',
//     component: MyQuestions, // question 路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // protocol 路由
//   },
// ]

//泰国kwamdeebusiness
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/introduce',
//     component: MyIntroduce, // introduce 路由
//   },
//   {
//     path: '/question',
//     component: MyQuestions, // question 路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // protocol 路由
//   },
// ]

//泰国jengtimber
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/introduce',
//     component: MyIntroduce, // introduce 路由
//   },
//   {
//     path: '/question',
//     component: MyQuestions, // question 路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // protocol 路由
//   },
// ]

//越南anhitinformation
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/about',
//     component: MyAbout, // about 路由
//   },
//   {
//     path: '/question',
//     component: MyQuestions, // question 路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // protocol 路由
//   },
// ]

//越南hungdaianapp
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/about',
//     component: MyAbout, // about 路由
//   },
//   {
//     path: '/question',
//     component: MyQuestions, // question 路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // protocol 路由
//   },
// ]

//越南donghaisoftware
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/about',
//     component: MyAbout, // about 路由
//   },
//   {
//     path: '/question',
//     component: MyQuestions, // question 路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // protocol 路由
//   },
// ]

//越南tandatphatmoney
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/about',
//     component: MyAbout, // about 路由
//   },
//   {
//     path: '/question',
//     component: MyQuestions, // question 路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // protocol 路由
//   },
// ]

//越南smartiesconsulting
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/about',
//     component: MyAbout, // about 路由
//   },
//   {
//     path: '/question',
//     component: MyQuestions, // question 路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // protocol 路由
//   },
// ]

//越南lvsoftcompanylimited
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/introduce',
//     component: MyIntroduce, // introduce 路由
//   },
//   {
//     path: '/question',
//     component: MyQuestions, // question 路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // protocol 路由
//   },
// ]

//墨西哥donapoyo
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/history',
//     component: MyHistory, // history 路由
//   },
//   {
//     path: '/question',
//     component: MyQuestions, // question 路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // protocol 路由
//   },
// ]

//泰国vcwealthloan
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/introduce',
//     component: MyIntroduce, // introduce 路由
//   },
//   {
//     path: '/question',
//     component: MyQuestions, // question 路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // protocol 路由
//   },
// ]

//墨西哥inmediprest
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/about',
//     component: MyAbout, // about 路由
//   },
//   {
//     path: '/introduce',
//     component: MyIntroduce, // introduce 路由
//   },
//   {
//     path: '/question',
//     component: MyQuestions, // question 路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // protocol 路由
//   },
// ]

//墨西哥foniclub
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/introduce',
//     component: MyIntroduce, // introduce 路由
//   },
//   {
//     path: '/about',
//     component: MyAbout, // about 路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // protocol 路由
//   },
// ]

//墨西哥alorsi
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/introduce',
//     component: MyIntroduce, // introduce 路由
//   },
//   {
//     path: '/about',
//     component: MyAbout, // about 路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // protocol 路由
//   },
// ]

//越南bonodilecold
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/introduce',
//     component: MyIntroduce, // introduce 路由
//   },
//   {
//     path: '/question',
//     component: MyQuestions, // question 路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // protocol 路由
//   },
// ]

//泰国mitrsinduan
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/introduce',
//     component: MyIntroduce, // introduce 路由
//   },
//   {
//     path: '/question',
//     component: MyQuestions, // question 路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // protocol 路由
//   },
//   {
//     path:'/bot',
//     component:MyBOT,//bot 路由
//   }
// ]

//墨西哥generandorlq
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/about',
//     component: MyAbout, // about 路由
//   },
//   {
//     path: '/question',
//     component: MyQuestions, // question 路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // protocol 路由
//   },
// ]

//墨西哥zchogorcredito
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/about',
//     component: MyAbout, // about 路由
//   },
//   {
//     path: '/question',
//     component: MyQuestions, // question 路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // protocol 路由
//   },
// ]

//墨西哥conficreosofom
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/about',
//     component: MyAbout, // about 路由
//   },
//   {
//     path: '/question',
//     component: MyQuestions, // question 路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // protocol 路由
//   },
// ]

//越南dreamcashddthanh
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/about',
//     component: MyAbout, // about 路由
//   },
//   {
//     path: '/question',
//     component: MyQuestions, // question 路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // protocol 路由
//   },
// ]

//越南chickenfiretown
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/about',
//     component: MyAbout, // about 路由
//   },
//   {
//     path: '/question',
//     component: MyQuestions, // question 路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // protocol 路由
//   },
// ]

//越南mangomoneyapp
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/about',
//     component: MyAbout, // about 路由
//   },
//   {
//     path: '/question',
//     component: MyQuestions, // question 路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // protocol 路由
//   },
// ]

//越南maybaengcompanylimitedapp
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/about',
//     component: MyAbout, // about 路由
//   },
//   {
//     path: '/question',
//     component: MyQuestions, // question 路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // protocol 路由
//   },
// ]

//墨西哥kartermax
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/about',
//     component: MyAbout, // about 路由
//   },
//   {
//     path: '/question',
//     component: MyQuestions, // question 路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // protocol 路由
//   },
// ]

//越南hauthuycompanylid
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/about',
//     component: MyAbout, // about 路由
//   },
//   {
//     path: '/question',
//     component: MyQuestions, // question 路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // protocol 路由
//   },
// ]

//越南hanvanthuycoldapp
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/about',
//     component: MyAbout, // about 路由
//   },
//   {
//     path: '/question',
//     component: MyQuestions, // question 路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // protocol 路由
//   },
// ]

//墨西哥fidecreo
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/introduce',
//     component: MyIntroduce, // introduce 路由
//   },
//   {
//     path: '/about',
//     component: MyAbout, // about 路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // protocol 路由
//   },
// ]

//墨西哥ttbluescapital
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/about',
//     component: MyAbout, // about 路由
//   },
//   {
//     path: '/question',
//     component: MyQuestions, // question 路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // protocol 路由
//   },
// ]

//泰国aira-aiful
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/about',
//     component: MyAbout, // about 路由
//   },
//   {
//     path: '/question',
//     component: MyQuestions, // question 路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // protocol 路由
//   },
// ]

//越南thaidinhphatapp
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/about',
//     component: MyAbout, // about 路由
//   },
//   {
//     path: '/question',
//     component: MyQuestions, // question 路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // protocol 路由
//   },
// ]

//越南speedyloanapp
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/about',
//     component: MyAbout, // about 路由
//   },
//   {
//     path: '/question',
//     component: MyQuestions, // question 路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // protocol 路由
//   },
// ]

//越南happycreditapp
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/about',
//     component: MyAbout, // about 路由
//   },
//   {
//     path: '/question',
//     component: MyQuestions, // question 路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // protocol 路由
//   },
// ]

//越南financialcompanionlvay
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/about',
//     component: MyAbout, // about 路由
//   },
//   {
//     path: '/question',
//     component: MyQuestions, // question 路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // protocol 路由
//   },
// ]

//墨西哥agroatoto
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/about',
//     component: MyAbout, // about 路由
//   },
//   {
//     path: '/question',
//     component: MyQuestions, // question 路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // protocol 路由
//   },
// ]

//越南loanwaveapp
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/about',
//     component: MyAbout, // about 路由
//   },
//   {
//     path: '/question',
//     component: MyQuestions, // question 路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // protocol 路由
//   },
// ]

//印尼kspbawanaartha
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/about',
//     component: MyAbout, // about 路由
//   },
//   {
//     path: '/introduce',
//     component: MyIntroduce, // introduce 路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // protocol 路由
//   },
// ]

//越南duongvuongtcomld
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/about',
//     component: MyAbout, // about 路由
//   },
//   {
//     path: '/question',
//     component: MyQuestions, // question 路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // protocol 路由
//   },
// ]

//泰国ngoentoryodcompany
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/about',
//     component: MyAbout, // about 路由
//   },
//   {
//     path: '/question',
//     component: MyQuestions, // question 路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // protocol 路由
//   },
// ]

//泰国worldleasecompany
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/about',
//     component: MyAbout, // about 路由
//   },
//   {
//     path: '/question',
//     component: MyQuestions, // question 路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // protocol 路由
//   },
// ]

//墨西哥ipesofom
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/about',
//     component: MyAbout, // about 路由
//   },
//   {
//     path: '/introduce',
//     component: MyIntroduce, // introduce 路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // protocol 路由
//   },
// ]

//越南mekongdevelopmentbank
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/about',
//     component: MyAbout, // about 路由
//   },
//   {
//     path: '/question',
//     component: MyQuestions, // question 路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // protocol 路由
//   },
// ]

//墨西哥theoutdoorsfinancil
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/about',
//     component: MyAbout, // about 路由
//   },
//   {
//     path: '/introduce',
//     component: MyIntroduce, // introduce 路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // protocol 路由
//   },
// ]

//越南prosperityandgrowthcommercialjointbank
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/about',
//     component: MyAbout, // about 路由
//   },
//   {
//     path: '/question',
//     component: MyQuestions, // question 路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // protocol 路由
//   },
// ]

//越南mongcaitokyoeahhtjsc
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/about',
//     component: MyAbout, // about 路由
//   },
//   {
//     path: '/question',
//     component: MyQuestions, // question 路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // protocol 路由
//   },
// ]

//墨西哥cherreyprestamos
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/about',
//     component: MyAbout, // about 路由
//   },
//   {
//     path: '/introduce',
//     component: MyIntroduce, // introduce 路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // protocol 路由
//   },
// ]

//越南quanbinhidbank
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/about',
//     component: MyAbout, // about 路由
//   },
//   {
//     path: '/question',
//     component: MyQuestions, // question 路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // protocol 路由
//   },
// ]

//泰国seamoneycompany
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/about',
//     component: MyAbout, // about 路由
//   },
//   {
//     path: '/question',
//     component: MyQuestions, // question 路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // protocol 路由
//   },
// ]

//越南maritimebankassetmanagement
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/about',
//     component: MyAbout, // about 路由
//   },
//   {
//     path: '/question',
//     component: MyQuestions, // question 路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // protocol 路由
//   },
// ]

//越南vietcomlimitedcompany
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/about',
//     component: MyAbout, // about 路由
//   },
//   {
//     path: '/question',
//     component: MyQuestions, // question 路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // protocol 路由
//   },
// ]

//越南bengreenvietnamcompanylimited
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/about',
//     component: MyAbout, // about 路由
//   },
//   {
//     path: '/question',
//     component: MyQuestions, // question 路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // protocol 路由
//   },
// ]

//越南emdaycompanylimited
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/about',
//     component: MyAbout, // about 路由
//   },
//   {
//     path: '/question',
//     component: MyQuestions, // question 路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // protocol 路由
//   },
// ]

//越南lvsoftloamapp
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/introduce',
//     component: MyIntroduce, // introduce 路由
//   },
//   {
//     path: '/question',
//     component: MyQuestions, // question 路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // protocol 路由
//   },
// ]

//墨西哥bmtservicios
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/about',
//     component: MyAbout, // about 路由
//   },
//   {
//     path: '/introduce',
//     component: MyIntroduce, // introduce 路由
//   },
// {
//   path: '/protocol',
//   component: MyProtocol, // protocol 路由
// },
// ]

//泰国creditcheck-ktcnanowallet
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/about',
//     component: MyAbout, // about 路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // protocol 路由
//   },
// ]

//越南dreamvayapploan
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/about',
//     component: MyAbout, // introduce 路由
//   },
//   {
//     path: '/question',
//     component: MyQuestions, // question 路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // protocol 路由
//   },
// ]

//墨西哥ceibamovilmx-ficreo
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/about',
//     component: MyAbout, // about 路由
//   },
//   {
//     path: '/introduce',
//     component: MyIntroduce, // introduce 路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // protocol 路由
//   },
// ]

//墨西哥desarrolloorigenesfofor
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/about',
//     component: MyAbout, // about 路由
//   },
//   {
//     path: '/introduce',
//     component: MyIntroduce, // introduce 路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // protocol 路由
//   },
// ]

//墨西哥distribuidoradecision
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/about',
//     component: MyAbout, // introduce 路由
//   },
//   {
//     path: '/question',
//     component: MyQuestions, // question 路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // protocol 路由
//   },
// ]

//墨西哥lithoimapcolor
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/about',
//     component: MyAbout, // introduce 路由
//   },
//   {
//     path: '/question',
//     component: MyQuestions, // question 路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // protocol 路由
//   },
// ]

//越南thanhquanggeneralcompany
const routes = [
  {
    path: '/',
    component: MyFirst, // 默认路由
  },
  {
    path: '/about',
    component: MyAbout, // introduce 路由
  },
  {
    path: '/question',
    component: MyQuestions, // question 路由
  },
  {
    path: '/protocol',
    component: MyProtocol, // protocol 路由
  },
]

const router = new VueRouter({
  mode: "hash", // 设置路由模式为 hash
  routes,
});

export default router;
